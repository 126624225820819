import { default as indexqPPdbskhpRMeta } from "/home/forge/base.farbcode.net/releases/20250127095941/client/pages/auth/index.vue?macro=true";
import { default as indexGjyMk9SIkCMeta } from "/home/forge/base.farbcode.net/releases/20250127095941/client/pages/auth/social/[organisation]/[provider]/index.vue?macro=true";
import { default as indexlyYv6lej5nMeta } from "/home/forge/base.farbcode.net/releases/20250127095941/client/pages/index.vue?macro=true";
import { default as organisationBaseData7VhSQ8vQKpMeta } from "/home/forge/base.farbcode.net/releases/20250127095941/client/pages/organisations/[id]/organisationBaseData.vue?macro=true";
import { default as _91id_93YJTME3ckMNMeta } from "/home/forge/base.farbcode.net/releases/20250127095941/client/pages/organisations/[id].vue?macro=true";
import { default as indexRQ0Ts2k5MoMeta } from "/home/forge/base.farbcode.net/releases/20250127095941/client/pages/organisations/index.vue?macro=true";
import { default as organisationsIrWDKfbL88Meta } from "/home/forge/base.farbcode.net/releases/20250127095941/client/pages/organisations.vue?macro=true";
import { default as dungeonCJur7HAV8tMeta } from "/home/forge/base.farbcode.net/releases/20250127095941/client/pages/secret/index/dungeon.vue?macro=true";
import { default as inputsKBWwiARyCeMeta } from "/home/forge/base.farbcode.net/releases/20250127095941/client/pages/secret/index/inputs.vue?macro=true";
import { default as indexBWo0TuojD0Meta } from "/home/forge/base.farbcode.net/releases/20250127095941/client/pages/secret/index.vue?macro=true";
import { default as language5jjm0VxqD4Meta } from "/home/forge/base.farbcode.net/releases/20250127095941/client/pages/settings/language.vue?macro=true";
import { default as _91id_93KPoJSrR5vQMeta } from "/home/forge/base.farbcode.net/releases/20250127095941/client/pages/settings/loginProviders/[id].vue?macro=true";
import { default as indexnzMs07SShyMeta } from "/home/forge/base.farbcode.net/releases/20250127095941/client/pages/settings/loginProviders/index.vue?macro=true";
import { default as _91_46_46_46slug_93MNGx6iuFKfMeta } from "/home/forge/base.farbcode.net/releases/20250127095941/client/pages/settings/mailTemplates/[name]/[...slug].vue?macro=true";
import { default as indexDEWxnxsqK4Meta } from "/home/forge/base.farbcode.net/releases/20250127095941/client/pages/settings/mailTemplates/index.vue?macro=true";
import { default as settingsDgwbXs06EBMeta } from "/home/forge/base.farbcode.net/releases/20250127095941/client/pages/settings.vue?macro=true";
import { default as userActivitiesUAbmhEJQ0wMeta } from "/home/forge/base.farbcode.net/releases/20250127095941/client/pages/users/[id]/userActivities.vue?macro=true";
import { default as userBaseDatakNdmPYRAz9Meta } from "/home/forge/base.farbcode.net/releases/20250127095941/client/pages/users/[id]/userBaseData.vue?macro=true";
import { default as userCommunicationstdxnq6nlZoMeta } from "/home/forge/base.farbcode.net/releases/20250127095941/client/pages/users/[id]/userCommunications.vue?macro=true";
import { default as _91id_93qXXJYUooRRMeta } from "/home/forge/base.farbcode.net/releases/20250127095941/client/pages/users/[id].vue?macro=true";
import { default as indexwVgORecguiMeta } from "/home/forge/base.farbcode.net/releases/20250127095941/client/pages/users/index.vue?macro=true";
import { default as userscz5cUUHTALMeta } from "/home/forge/base.farbcode.net/releases/20250127095941/client/pages/users.vue?macro=true";
import { default as _91_46_46_46slug_93VZzdCGSEyrMeta } from "/home/forge/base.farbcode.net/releases/20250127095941/client/pages/wiki/[...slug].vue?macro=true";
export default [
  {
    name: "auth",
    path: "/auth",
    meta: indexqPPdbskhpRMeta || {},
    component: () => import("/home/forge/base.farbcode.net/releases/20250127095941/client/pages/auth/index.vue").then(m => m.default || m)
  },
  {
    name: "auth-social-organisation-provider",
    path: "/auth/social/:organisation()/:provider()",
    meta: indexGjyMk9SIkCMeta || {},
    component: () => import("/home/forge/base.farbcode.net/releases/20250127095941/client/pages/auth/social/[organisation]/[provider]/index.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/forge/base.farbcode.net/releases/20250127095941/client/pages/index.vue").then(m => m.default || m)
  },
  {
    name: organisationsIrWDKfbL88Meta?.name,
    path: "/organisations",
    meta: organisationsIrWDKfbL88Meta || {},
    component: () => import("/home/forge/base.farbcode.net/releases/20250127095941/client/pages/organisations.vue").then(m => m.default || m),
    children: [
  {
    name: "organisations-id",
    path: ":id()",
    meta: _91id_93YJTME3ckMNMeta || {},
    component: () => import("/home/forge/base.farbcode.net/releases/20250127095941/client/pages/organisations/[id].vue").then(m => m.default || m),
    children: [
  {
    name: "organisations-id-organisationBaseData",
    path: "organisationBaseData",
    component: () => import("/home/forge/base.farbcode.net/releases/20250127095941/client/pages/organisations/[id]/organisationBaseData.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "organisations",
    path: "",
    meta: indexRQ0Ts2k5MoMeta || {},
    component: () => import("/home/forge/base.farbcode.net/releases/20250127095941/client/pages/organisations/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "secret",
    path: "/secret",
    component: () => import("/home/forge/base.farbcode.net/releases/20250127095941/client/pages/secret/index.vue").then(m => m.default || m),
    children: [
  {
    name: "secret-index-dungeon",
    path: "dungeon",
    component: () => import("/home/forge/base.farbcode.net/releases/20250127095941/client/pages/secret/index/dungeon.vue").then(m => m.default || m)
  },
  {
    name: "secret-index-inputs",
    path: "inputs",
    component: () => import("/home/forge/base.farbcode.net/releases/20250127095941/client/pages/secret/index/inputs.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "settings",
    path: "/settings",
    meta: settingsDgwbXs06EBMeta || {},
    component: () => import("/home/forge/base.farbcode.net/releases/20250127095941/client/pages/settings.vue").then(m => m.default || m),
    children: [
  {
    name: "settings-language",
    path: "language",
    component: () => import("/home/forge/base.farbcode.net/releases/20250127095941/client/pages/settings/language.vue").then(m => m.default || m)
  },
  {
    name: "settings-loginProviders-id",
    path: "loginProviders/:id()",
    meta: _91id_93KPoJSrR5vQMeta || {},
    component: () => import("/home/forge/base.farbcode.net/releases/20250127095941/client/pages/settings/loginProviders/[id].vue").then(m => m.default || m)
  },
  {
    name: "settings-loginProviders",
    path: "loginProviders",
    meta: indexnzMs07SShyMeta || {},
    component: () => import("/home/forge/base.farbcode.net/releases/20250127095941/client/pages/settings/loginProviders/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-mailTemplates-name-slug",
    path: "mailTemplates/:name()/:slug(.*)*",
    meta: _91_46_46_46slug_93MNGx6iuFKfMeta || {},
    component: () => import("/home/forge/base.farbcode.net/releases/20250127095941/client/pages/settings/mailTemplates/[name]/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "settings-mailTemplates",
    path: "mailTemplates",
    meta: indexDEWxnxsqK4Meta || {},
    component: () => import("/home/forge/base.farbcode.net/releases/20250127095941/client/pages/settings/mailTemplates/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: userscz5cUUHTALMeta?.name,
    path: "/users",
    meta: userscz5cUUHTALMeta || {},
    component: () => import("/home/forge/base.farbcode.net/releases/20250127095941/client/pages/users.vue").then(m => m.default || m),
    children: [
  {
    name: "users-id",
    path: ":id()",
    meta: _91id_93qXXJYUooRRMeta || {},
    component: () => import("/home/forge/base.farbcode.net/releases/20250127095941/client/pages/users/[id].vue").then(m => m.default || m),
    children: [
  {
    name: "users-id-userActivities",
    path: "userActivities",
    component: () => import("/home/forge/base.farbcode.net/releases/20250127095941/client/pages/users/[id]/userActivities.vue").then(m => m.default || m)
  },
  {
    name: "users-id-userBaseData",
    path: "userBaseData",
    meta: userBaseDatakNdmPYRAz9Meta || {},
    component: () => import("/home/forge/base.farbcode.net/releases/20250127095941/client/pages/users/[id]/userBaseData.vue").then(m => m.default || m)
  },
  {
    name: "users-id-userCommunications",
    path: "userCommunications",
    component: () => import("/home/forge/base.farbcode.net/releases/20250127095941/client/pages/users/[id]/userCommunications.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "users",
    path: "",
    meta: indexwVgORecguiMeta || {},
    component: () => import("/home/forge/base.farbcode.net/releases/20250127095941/client/pages/users/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "wiki-slug",
    path: "/wiki/:slug(.*)*",
    meta: _91_46_46_46slug_93VZzdCGSEyrMeta || {},
    component: () => import("/home/forge/base.farbcode.net/releases/20250127095941/client/pages/wiki/[...slug].vue").then(m => m.default || m)
  }
]